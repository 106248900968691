<template>
  <div>
    <div class="promocao-editar">
      <div class="conteudo-interno">
        <div class="nova-comunicacao-container">
          <h1>Editar Campanha</h1>
          <transition name="fade">
            <FormularioCampanhas v-if="dadosCarregados" :editar="dados" @salvar="salvar($event)" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const FormularioCampanhas = () => import("@/components/elementos/paginas/FormularioCampanhas.vue");
export default {
  name: "TreinamentosEditar",
  components: {
    FormularioCampanhas,
  },
  data() {
    return {
      idProduto: null,
      IdPromocao: null,
      IdMecanica: null,
      dadosCarregados: false,
      idServicoPromocaoPromoshot: null,
      dados: {
        promocaoPromoshotData: null,
        precisaDeRegulamentoObrigatorio: null,
        apelido: null,
        empresaDoPromoshot: null,
        imagemThumb: null,
        imagemDesk: null,
        imagemMobile: null,
        cliente: null,
        titulo: null,
        mecanica: null,
        mecanicas: {
          quantidade: {
            qtdMinima: null,
            valor: null,
            produtos: [],
          },
          produtos: {
            qtdProdutos: null,
            valor: null,
            produtos: [],
          },
          valor: {
            valorMinimo: null,
            valorPontos: null,
            produtos: [],
          },
          pontuam: {
            produtos: [],
          },
        },
        dataInicio: null,
        dataFim: null,
        descricao: null,
      },
    };
  },
  methods: {
    listarPromocaoPromoshot() {
      // promocaoPromoshot().then((resp) => {
      //   this.dados.promocaoPromoshotData = resp.data;
      // })
    },
    buscar() {
      // buscarPromocoes(id).then((resp) => {
      //   if(resp.data.vinculoPromoShot) {
      //     this.$store.commit("promocoes/SET_PROMOCAO_ACEITA_REGULAMENTO", resp.data.regulamentoPromoShot);
      //     this.dados.precisaDeRegulamentoObrigatorio = [{id: 2, name: "SIM"}]
      //     const empresaPromoshot = this.dados.promocaoPromoshotData.filter(empresa => {
      //       return empresa.id === resp.data.idServicoPromocao
      //     });
      //     this.idServicoPromocaoPromoshot = resp.data.idServicoPromocao
      //     this.$store.commit("promocoes/SET_PROMOCAO_PROMOSHOT", empresaPromoshot[0]);
      //     this.dados.empresaDoPromoshot = empresaPromoshot;
          
      //   }
      //   this.dados.titulo = resp.data.nome;
      //   this.dados.apelido = resp.data.apelido;
      //   this.dados.validacaoCnpj = resp.data.validacaoCnpj;
      //   this.IdMecanica = resp.data.mecanica.id;
      //   this.dados.cliente = resp.data.mecanica.cliente.id;
      //   this.dados.mecanica = resp.data.mecanica.tipoMecanica.id;
      //   if (resp.data.mecanica.tipoMecanica.id == 1) {
      //     this.dados.mecanicas.quantidade.qtdMinima = resp.data.mecanica.valor;
      //     this.dados.mecanicas.quantidade.valor = resp.data.mecanica.pontos;
      //     this.dados.mecanicas.quantidade.produtos = resp.data.mecanica.produtosParticipante.map((item) => {
      //       return {
      //         id: item.produto.id,
      //         nome: item.produto.nome,
      //         descricao: item.descricao,
      //         quantidade: item.valor,
      //         valorBonus: item.pontos,
      //       };
      //     });
      //   } else if (resp.data.mecanica.tipoMecanica.id == 2) {
          
      //     this.dados.mecanicas.produtos.qtdProdutos = resp.data.mecanica.valor;
      //     this.dados.mecanicas.produtos.valor = resp.data.mecanica.pontos;
      //     this.dados.mecanicas.produtos.produtos = resp.data.mecanica.produtosParticipante.map((item) => {
      //       return {
      //         id: item.produto.id,
      //         nome: item.produto.nome,
      //         descricao: item.descricao,
      //         quantidade: item.valor,
      //         valorBonus: item.pontos,
      //       };
      //     });
      //   } else if (resp.data.mecanica.tipoMecanica.id == 3) {
      //     this.dados.mecanicas.valor.valorMinimo = resp.data.mecanica.valor;
      //     this.dados.mecanicas.valor.valorPontos = resp.data.mecanica.pontos;
      //     this.dados.mecanicas.valor.produtos = resp.data.mecanica.produtosParticipante.map((item) => {
      //       return {
      //         id: item.produto.id,
      //         nome: item.produto.nome,
      //         descricao: item.descricao,
      //         quantidade: item.valor,
      //         valorBonus: item.pontos,
      //       };
      //     });
      //   } else if (resp.data.mecanica.tipoMecanica.id == 4) {
      //     this.dados.mecanicas.pontuam.produtos = resp.data.mecanica.produtosParticipante.map((item) => {
      //       return {
      //         id: item.produto.id,
      //         nome: item.produto.nome,
      //         quantidade: item.valor,
      //         valorBonus: item.pontos,
      //         descricao: item.descricao,
      //         valorPontos: item.valeMais == 1 ? { id: 1, nome: "Sem Bônus" } : item.valeMais == 2 ? { id: 1, nome: "2x Vlr Total" } : false,
      //         ativo: true,
      //       };
      //     });
      //   } else if (resp.data.mecanica.tipoMecanica.id == 5) {
      //     this.dados.mecanicas.valor.valorMinimo = resp.data.mecanica.valor;
      //     this.dados.mecanicas.valor.valorPontos = resp.data.mecanica.pontos;
      //     this.dados.mecanicas.valor.produtos = resp.data.mecanica.produtosParticipante.map((item) => {
      //       return {
      //         id: item.produto.id,
      //         nome: item.produto.nome,
      //         descricao: item.descricao,
      //         quantidade: item.valor,
      //         valorBonus: item.pontos,
      //       };
      //     });
      //   }

      //   this.dados.dataInicio = resp.data.dataInicial;
      //   this.dados.dataFim = resp.data.dataFinal;
      //   this.dados.descricao = resp.data.conteudo;
      //   this.dados.imagemThumb = resp.data.imagemDestaque;
      //   this.dados.imagemDesk = resp.data.imagemDesktop;
      //   this.dados.imagemMobile = resp.data.imagemMobile;
      //   this.dadosCarregados = true;
      // });
    },
    salvar(e) {
      const data = new FormData();
      // Fixos
      data.append("IdPromocao", this.IdPromocao);
      data.append("IdTipoAbrangencia", 1);
      data.append("Diariamente", true);
      data.append("Ativo", true);
      // Infos

      if(e.VinculoPromoShot) {
        data.append("VinculoPromoShot", e.VinculoPromoShot)
      }
      if(e.IdServicoPromocao) {
        data.append("IdServicoPromocao", e.IdServicoPromocao)
      }
      if(e.RegulamentoPromoShot) {
        data.append("RegulamentoPromoShot", e.RegulamentoPromoShot)
      }

      data.append("Apelido", e.apelido);

      data.append("Id", this.idProduto);
      data.append("Nome", e.titulo);
      data.append("ValidacaoCnpj", e.validacaoCnpj);
      data.append("Mecanica.IdCliente", e.cliente.id);
      data.append(
        "DataInicial",
        new Date(e.dataInicio)
          .toLocaleDateString()
          .split("/")
          .reverse()
          .join("-")
      );
      data.append(
        "DataFinal",
        new Date(e.dataFim)
          .toLocaleDateString()
          .split("/")
          .reverse()
          .join("-")
      );
      data.append("Conteudo", e.descricao);
      data.append("Mecanica.IdTipoMecanica", e.mecanica.id);
      data.append("Mecanica.IdMecanica", this.IdMecanica);
      if (e.mecanica.id == 1) {
        data.append("Mecanica.Valor", e.mecanicas.quantidade.qtdMinima);
        data.append("Mecanica.Pontos", e.mecanicas.quantidade.valor);
        e.mecanicas.quantidade.produtos.forEach((item, index) => {
          data.append(`Mecanica.ProdutosParticipante[${index}].ValeMais`, "1");
          data.append(`Mecanica.ProdutosParticipante[${index}].Ativo`, true);
          data.append(`Mecanica.ProdutosParticipante[${index}].Descricao`, item.descricao);
          data.append(`Mecanica.ProdutosParticipante[${index}].IdProduto`, item.id);
          data.append(`Mecanica.ProdutosParticipante[${index}].Valor`, item.quantidade);
          data.append(`Mecanica.ProdutosParticipante[${index}].Pontos`, item.valorBonus);
        });
      } else if (e.mecanica.id == 2) {
        
        data.append("Mecanica.Valor", e.mecanicas.produtos.qtdProdutos);
        data.append("Mecanica.Pontos", e.mecanicas.produtos.valor);
        e.mecanicas.produtos.produtos.forEach((item, index) => {
          data.append(`Mecanica.ProdutosParticipante[${index}].ValeMais`, "1");
          data.append(`Mecanica.ProdutosParticipante[${index}].Ativo`, true);
          data.append(`Mecanica.ProdutosParticipante[${index}].Descricao`, item.descricao);
          data.append(`Mecanica.ProdutosParticipante[${index}].IdProduto`, item.id);
          data.append(`Mecanica.ProdutosParticipante[${index}].Valor`, item.quantidade);
          data.append(`Mecanica.ProdutosParticipante[${index}].Pontos`, item.valorBonus);
        });
      } else if (e.mecanica.id == 3) {
        data.append("Mecanica.Valor", e.mecanicas.valor.valorMinimo);
        data.append("Mecanica.Pontos", e.mecanicas.valor.valorPontos);
        e.mecanicas.valor.produtos.forEach((item, index) => {
          data.append(`Mecanica.ProdutosParticipante[${index}].ValeMais`, "1");
          data.append(`Mecanica.ProdutosParticipante[${index}].Ativo`, true);
          data.append(`Mecanica.ProdutosParticipante[${index}].Descricao`, item.descricao);
          data.append(`Mecanica.ProdutosParticipante[${index}].IdProduto`, item.id);
          data.append(`Mecanica.ProdutosParticipante[${index}].Valor`, item.quantidade);
          data.append(`Mecanica.ProdutosParticipante[${index}].Pontos`, item.valorBonus);
        });
      } else if (e.mecanica.id == 4) {
        e.mecanicas.pontuam.produtos.forEach((item, index) => {
          data.append(`Mecanica.ProdutosParticipante[${index}].ValeMais`, item.valorPontos.id == 2 ? 1 : 2);
          data.append(`Mecanica.ProdutosParticipante[${index}].Ativo`, true);
          data.append(`Mecanica.ProdutosParticipante[${index}].Valor`, item.quantidade);
          data.append(`Mecanica.ProdutosParticipante[${index}].Pontos`, item.valorBonus);
          data.append(`Mecanica.ProdutosParticipante[${index}].Descricao`, item.descricao);
          data.append(`Mecanica.ProdutosParticipante[${index}].IdProduto`, item.id);
        });
      } else if (e.mecanica.id == 5) {
        data.append("Mecanica.Valor", e.mecanicas.valor.valorMinimo);
        data.append("Mecanica.Pontos", e.mecanicas.valor.valorPontos);
        e.mecanicas.valor.produtos.forEach((item, index) => {
          data.append(`Mecanica.ProdutosParticipante[${index}].ValeMais`, "1");
          data.append(`Mecanica.ProdutosParticipante[${index}].Ativo`, true);
          data.append(`Mecanica.ProdutosParticipante[${index}].Descricao`, item.descricao);
          data.append(`Mecanica.ProdutosParticipante[${index}].IdProduto`, item.id);
          data.append(`Mecanica.ProdutosParticipante[${index}].Valor`, item.quantidade);
          data.append(`Mecanica.ProdutosParticipante[${index}].Pontos`, item.valorBonus);
        });
      }
      // Imagen Desk
      if (e.imagemDesk) {
        data.append("ImagemDesktop", e.imagemDesk);
      }else{
        data.append("ImagemDesktop", this.dados.imagemDesk);
      }
      // Imagen Mobile
      if (e.imagemMobile) {
        data.append("ImagemMobile", e.imagemMobile);
      }else{
        data.append("ImagemMobile", this.dados.imagemMobile);
      }
      // Imagen Thumb
      if (e.imagemThumb) {
        data.append("ImagemDestaque", e.imagemThumb);
      }else{
        data.append("ImagemDestaque", this.dados.imagemThumb);
      }
      // Planilha 
      if(e.planilha){
        data.append("Planilha", e.planilha);
      }
      //  editarPromocoes(data).then((resp) => {
      //    this.$store.commit("promocoes/SET_CARREGADO", false);
      //    this.$router.push("/promocoes/listar");
      //    this.$store.commit("SET_MODAL", {
      //      ativado: true,
      //      mensagem: resp.data.message,
      //      tipo: resp.data.sucesso ? "sucesso" : "erro",
      //    });
      //  })
    },
  },
  mounted() {
    const id = this.$route.params.id;
    this.IdPromocao = id;
    this.idProduto = id;
    this.listarPromocaoPromoshot();
    this.buscar(id);
  },
};
</script>

<style lang="scss">
.promocao-editar {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
</style>
